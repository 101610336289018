import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
// import * as amplitude from '@amplitude/analytics-browser';
const Login = () => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const trackAndNavigate = () => {
    // amplitude.track('Successful log in ', { email_address: user?.email });
    // amplitude.setUserId(user?.email);
    navigate('/');
  };
  if (isAuthenticated ? trackAndNavigate() : loginWithRedirect());
  return null;
};

export default Login;
